
import Vue, { PropType } from 'vue';

import { BodyMainInspiration, EditoInspirations } from '@/domain/edito/types';

export default Vue.extend({
  name: 'EditoBlockCallouts',
  props: {
    content: {
      type: Object as PropType<EditoInspirations>,
      required: true,
    },
  },
  computed: {
    callouts(): (BodyMainInspiration | undefined)[] {
      const { first, second } = this.content;

      return [first, second];
    },
  },
});
