
import Vue, { PropType } from 'vue';

import { EEmitClickEvent } from '@/domain/core/tracking/EventName.enum';
import { BaseCardContent } from '@/infrastructure/core/components/card/BaseCard.interface';
import { ClickEventPayload } from '@/infrastructure/externalServices/gtm/generic/ClickEvent.interface';

export default Vue.extend({
  name: 'CarouselCard',
  props: {
    slides: {
      type: Array as PropType<BaseCardContent[]>,
      required: true,
    },
  },
  methods: {
    handleClickOnSlide(slide: BaseCardContent): void {
      const { cta: name, link: url } = slide;

      this.$emit(EEmitClickEvent.CarouselSlide, { name, url } as ClickEventPayload);
    },
  },
});
