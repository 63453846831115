import { render, staticRenderFns } from "./CarouselProductTailoredSuggestions.vue?vue&type=template&id=f4847dd2"
import script from "./CarouselProductTailoredSuggestions.vue?vue&type=script&lang=ts"
export * from "./CarouselProductTailoredSuggestions.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PlaceholderCarouselProduct: require('/home/runner/work/webapp/webapp/components/organisms/placeholder/PlaceholderCarouselProduct.vue').default,BaseLink: require('/home/runner/work/webapp/webapp/components/atoms/link/BaseLink.vue').default,BaseButton: require('/home/runner/work/webapp/webapp/components/atoms/button/BaseButton.vue').default,CarouselProduct: require('/home/runner/work/webapp/webapp/components/organisms/carousel/CarouselProduct.vue').default})
