
import Vue, { PropType } from 'vue';

import BaseLink from '@/components/atoms/link/BaseLink.vue';
import { ApiEditoBlockCallToAction } from '@/domain/edito/types';

export default Vue.extend({
  name: 'EditoBlockCallToAction',
  components: {
    BaseLink,
  },
  props: {
    content: {
      type: Object as PropType<ApiEditoBlockCallToAction>,
      required: true,
    },
  },
  computed: {
    sectionStyle(): Record<string, string | undefined> {
      const { backgroundColor, fontColor: color } = this.content;

      return {
        backgroundColor,
        color,
      };
    },
  },
});
