
import Vue from 'vue';

import { ELocalStorageItem } from '@/domain/core/LocalStorageItem.enum';
import { Product } from '@/domain/product/types';
import { EGtmEcommerceListName } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

export default Vue.extend({
  name: 'CarouselProductLastViewed',
  data() {
    return {
      lastItemsViewed: null as string[] | null,
      products: [] as Product[],
      EGtmEcommerceListName,
    };
  },
  fetchOnServer: false,
  async fetch(): Promise<void> {
    const rawLastItemsViewed = localStorage.getItem(ELocalStorageItem.StoredItemsViewed);

    if (!rawLastItemsViewed) {
      return;
    }

    const parsedItems: string[] = rawLastItemsViewed ? JSON.parse(rawLastItemsViewed) : [];

    this.lastItemsViewed = parsedItems; // Update data to trigger this.hasLastItemsViewed.

    const products = await this.$repositories.product.getProducts(parsedItems);
    const orderedProducts = products?.items
      ?.slice() // Avoid mutating original array.
      ?.sort((a, b) => parsedItems.indexOf(a.sku) - parsedItems.indexOf(b.sku)) || [];

    this.products = orderedProducts;
  },
  computed: {
    hasLastItemsViewed(): boolean {
      return !!this.lastItemsViewed?.length;
    },
  },
});
