
import Vue, { PropType } from 'vue';

import { BaseCardContent } from '@/infrastructure/core/components/card/BaseCard.interface';
import { imagePlaceholderId } from '@/infrastructure/externalServices/imageCdn/types';

export default Vue.extend({
  name: 'BaseCard',
  props: {
    content: {
      type: Object as PropType<BaseCardContent>,
      required: true,
    },
    imageSizes: {
      type: String,
      default: '',
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imagePlaceholderId,
    };
  },
});
