
import Vue, { PropType } from 'vue';

import { EEmitClickEvent } from '@/domain/core/tracking/EventName.enum';
import { BodyMainInspiration, EBackgroundType } from '@/domain/edito/types';
import { CalloutClickEventPayload } from '@/infrastructure/externalServices/gtm/edito/EventPayload.interface';
import { imagePlaceholderId } from '@/infrastructure/externalServices/imageCdn/types';

export default Vue.extend({
  name: 'EditoCallout',
  props: {
    content: {
      type: Object as PropType<BodyMainInspiration>,
      required: true,
    },
  },
  data() {
    return {
      imagePlaceholderId,
    };
  },
  computed: {
    hasImage(): boolean {
      return this.content.backgroundType === EBackgroundType.Image;
    },
    bgColor(): string | null {
      let color = null;

      if (this.content.backgroundType === EBackgroundType.SolidColor && this.content.backgroundColor) {
        color = this.content.backgroundColor;
      }

      return color;
    },
  },
  methods: {
    handleClick($event: Event): void {
      const { textContent, nodeName, href } = $event.target as HTMLAnchorElement;

      const payload: CalloutClickEventPayload = {
        name: textContent,
        url: href,
        image: this.content?.image || '',
        target: nodeName,
      };

      this.$emit(EEmitClickEvent.Callout, payload);
    },
  },
});
