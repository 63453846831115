
import Vue, { PropType } from 'vue';

const placeholder = '{custom}';

enum ETimeout {
  Deletion = 50,
  NextWord = 1200,
  Start = 500,
  Typing = 120,
}

export default Vue.extend({
  name: 'EditoBlockTyper',
  props: {
    sentence: {
      type: String,
      required: true,
      validator(value: string) {
        return value.includes(placeholder);
      },
    },
    words: {
      type: Array as PropType<string[]>,
      required: true,
      validator(value: string[]) {
        return value?.length > 1;
      },
    },
  },
  data() {
    return {
      currentTimeout: null as ReturnType<typeof setTimeout> | null,
      remainingChars: [] as string[],
      isWaitingForNextWord: true,
      timeoutDelay: ETimeout.Start,
      typedChars: [] as string[],
      currentWordIndex: -1,
    };
  },
  computed: {
    typedWord(): string {
      return this.typedChars.join('');
    },
    staticSentence(): { end: string; start: string } {
      const fragments = this.sentence?.split(placeholder) || [];
      const [start, end] = fragments.map((fragment) => fragment?.trim() || '');

      return {
        end,
        start,
      };
    },
    shouldType(): boolean {
      return this.remainingChars.length > 0;
    },
    shouldAwait(): boolean {
      return !this.isWaitingForNextWord && !this.shouldType;
    },
    shouldDelete(): boolean {
      return this.remainingChars.length === 0 && this.typedChars.length > 0;
    },
    shouldTypeNewWord(): boolean {
      return this.remainingChars.length === 0 && this.typedChars.length === 0;
    },
  },
  mounted() {
    this.currentTimeout = setTimeout(this.startTyping, this.timeoutDelay);
  },
  beforeDestroy(): void {
    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }
  },
  methods: {
    awaitNextWord(): void {
      this.setTimeoutDelay(ETimeout.NextWord);

      this.isWaitingForNextWord = true;
    },
    deleteCharacter(): void {
      this.setTimeoutDelay(ETimeout.Deletion);

      this.typedChars.pop();
    },
    startTyping(): void {
      if (this.words?.length) {
        this.switchToNextWord();
        this.setTimeoutDelay(ETimeout.Typing);

        this.currentTimeout = setTimeout(this.type, this.timeoutDelay);
      }
    },
    switchToNextWord(): void {
      if (this.currentWordIndex < this.words.length - 1) {
        this.currentWordIndex += 1;
      } else {
        this.currentWordIndex = 0;
      }

      this.remainingChars = [...this.words[this.currentWordIndex]];
      this.isWaitingForNextWord = false;
    },
    type(): void {
      if (this.shouldType) {
        this.typeCharacter();
      } else if (this.shouldAwait) {
        this.awaitNextWord();
      } else if (this.shouldDelete) {
        this.deleteCharacter();
      } else if (this.shouldTypeNewWord) {
        this.switchToNextWord();
        this.typeCharacter();
      }

      this.currentTimeout = setTimeout(this.type, this.timeoutDelay);
    },
    typeCharacter(): void {
      this.setTimeoutDelay(ETimeout.Typing);

      this.typedChars.push(this.remainingChars.shift()!);
    },
    setTimeoutDelay(timeout: number): void {
      this.timeoutDelay = timeout;
    },
  },
});
