
import Vue from 'vue';

import { productCarouselOptions } from '@/config/plugins/SsrCarouselOptions';

export default Vue.extend({
  name: 'PlaceholderCarouselProduct',
  props: {
    fakeSlidesCount: {
      type: Number,
      default: 5,
    },
    hasTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productCarouselOptions,
    };
  },
  computed: {
    fakeSlides(): number[] {
      return Array.from({ length: this.fakeSlidesCount });
    },
  },
});
